import { getExpirationDate } from "./getExpirationDate";
export const setTokens = ({
  access,
  refresh,
}: {
  access: string;
  refresh: string;
}) => {
  useCookie("access", { expires: getExpirationDate() }).value = access;
  useCookie("refresh", { expires: getExpirationDate() }).value = refresh;
};
