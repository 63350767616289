<template>
  <NuxtLayout>
    <NuxtPage />
    <ClientOnly>
      <Transition name="notification">
        <Notification v-if="notificationsStore.notificationIsRequired" />
      </Transition>
    </ClientOnly>
  </NuxtLayout>
</template>

<script setup lang="ts">
import * as Sentry from "@sentry/vue";
import { getAccess } from "./utils/tokens/getAccess";

const notificationsStore = useNotificationsStore();
const userStore = useUserStore();
if (getAccess() && !userStore.userData) {
  try {
    await userStore.getUser();
  } catch (e) {
    Sentry.captureException(e);
  }
}
</script>
