import { removeTokens } from "~/utils/tokens/removeTokens";
import { setTokens } from "~/utils/tokens/setTokens";
import { getAccess } from "~/utils/tokens/getAccess";
import { getRefresh } from "~/utils/tokens/getRefresh";
import { normalize } from "~/utils/url/normalize";
import * as Sentry from "@sentry/vue";
import type { Orders } from "~/types/order.ts";

export type User = {
  id: number;
  email: string;
  nickname: string;
  avatar_img: string | null;
  balance: number;
};

export type Credentials = {
  email: string;
  password: string;
};

export type SocialsIds = {
  vk_id: string | null;
  telegram_id: string | null;
  steam_id: string | null;
  facebook_id: string | null;
  yandex_id: string | null;
};

export type TokensPair = {
  access: string;
  refresh: string;
};

export const useUserStore = defineStore("userStore", () => {
  let tokensPair: TokensPair = { access: "", refresh: "" };
  const userData = ref<User | null>(null);
  const socialIds = ref<SocialsIds | null>(null);
  const userOrders = ref<Orders | null>(null);
  const authPopupIsRequired = ref(false);
  const userIsAuthorized = computed(() => getAccess() && getRefresh());
  const runtimeConfig = useRuntimeConfig();
  const baseURL = isClient()
    ? `${runtimeConfig.public.clientApiBase}api/`
    : `${runtimeConfig.public.serverApiBase}api/`;
  const orderPerPage = 50;
  // индикатор отображения модалки предупреждения удаления аккаунта
  const showUserDeletePopup = ref(false);

  function getAuthorizationHeader(): { Authorization: string } | {} {
    const access = isServer() ? tokensPair.access || getAccess() : getAccess();
    if (access) {
      return { Authorization: `Bearer ${access}` };
    } else {
      return {};
    }
  }

  function showAuthPopup() {
    document.documentElement.style.overflow = "hidden";
    authPopupIsRequired.value = true;
  }

  function hideAuthPopup() {
    authPopupIsRequired.value = false;
    document.documentElement.style.overflow = "";
  }

  function updateUserData(data: User) {
    userData.value = data;
  }

  function loginUsingCredentials(credentials: Credentials) {
    return $fetch<TokensPair>("/auth/jwt/create/", {
      method: "POST",
      baseURL,
      credentials: "include",
      body: {
        ...credentials,
      },
    });
  }

  function logout() {
    userData.value = null;
    removeTokens();
    useProductsStore().deleteOrderFromStorage();
  }

  // Устанавливаем ID пользователя для виджета поддержки
  async function setUserIdTalkMe(userId: number): Promise<void> {
    const checkTalkMe = () => {
      if (window.TalkMe) {
        // Вызываем встроенный метод TalkMe для изменения данных клиента
        window.TalkMe("setClientInfo", {
          custom: {
            user_id: userId.toString(),
          },
        });
      } else {
        setTimeout(checkTalkMe, 500); // Проверяем снова через 500 мс
      }
    };
    checkTalkMe();
  }

  async function getUser() {
    try {
      const data = await $fetch<User>("/auth/profile/", {
        retry: false,
        baseURL,
        headers: getAuthorizationHeader(),
        credentials: "include",
      });
      updateUserData(data);
      // Т.к. TalkMe использует window, при вызове на сервере
      // получает ошибку в Sentry. Поэтому ф-ию вызываем только на клиенте
      if (isClient()) setUserIdTalkMe(data.id);
    } catch (error: any) {
      const refresh =
        (isServer()
          ? tokensPair.refresh || getRefresh() || ""
          : getRefresh()) || "";
      if (refresh && error.statusCode === 401) {
        try {
          await refreshTokens(refresh);
          await getUser();
        } catch (e) {
          removeTokens();
          throw e;
        }
      }
      throw error;
    }
  }

  async function processErrorLoading(e: any, cb: Function, ...args: any) {
    if (e.statusCode === 401) {
      const refresh =
        (isServer() ? tokensPair.refresh || getRefresh() : getRefresh()) || "";
      try {
        await refreshTokens(refresh);
        await cb(...args);
      } catch (error: any) {
        Sentry.captureException(error);
      }
    }
  }

  async function getUserOrders() {
    try {
      const data = await $fetch<Orders>(
        `/orders/?page_size=${orderPerPage}&page=${1}`,
        {
          baseURL,
          credentials: "include",
          headers: getAuthorizationHeader(),
        },
      );
      if (
        data &&
        "count" in data &&
        "next" in data &&
        "results" in data &&
        Array.isArray(data.results)
      ) {
        userOrders.value = data;
      }
    } catch (error: any) {
      if (error.statusCode === 401) {
        const refresh = getRefresh() || "";
        await refreshTokens(refresh);
        await getUserOrders();
      } else {
        throw error;
      }
      Sentry.captureException(error);
    }
  }

  function getSocialRedirectUri(social: string): string {
    const url = new URL(baseURL);
    return normalize(`${url.origin}/auth/${social}/redirect/`);
  }

  function getSocialAuthUrl(social: string, redirectUri: string) {
    const path = `/auth/social/${social}/url/`;
    const parameters = `?redirect_uri=${redirectUri}`;
    const fullPath = path + parameters;
    return $fetch<{ url: string }>(fullPath, {
      baseURL,
      credentials: "include",
    });
  }

  function refreshTokens(refresh: string) {
    return $fetch<TokensPair>("/auth/jwt/refresh/", {
      method: "POST",
      baseURL,
      credentials: "include",
      body: { refresh },
    }).then((tokens) => {
      // на стороне сервера useCookie не вернет актуальные значения,
      // для дальнейшего использования новых данных требуется использовать
      // временную копию
      if (isServer()) tokensPair = tokens;
      setTokens(tokens);
    });
  }

  function sendSocialCode(social: string, code: string, redirect_uri: string) {
    return $fetch<TokensPair & { is_signed_up: boolean }>(
      `/auth/social/${social}/auth/`,
      {
        retry: false,
        method: "POST",
        baseURL,
        credentials: "include",
        body: {
          code,
          redirect_uri,
        },
      },
    );
  }

  function bindSocial(social: string, code: string, redirect_uri: string) {
    return $fetch<void>(`/auth/social/${social}/bind/`, {
      method: "POST",
      baseURL,
      credentials: "include",
      headers: getAuthorizationHeader(),
      body: {
        code,
        redirect_uri,
      },
    });
  }

  function unbindSocial(social: string) {
    return $fetch<void>(`/auth/social/${social}/unbind/`, {
      method: "POST",
      baseURL,
      credentials: "include",
      headers: getAuthorizationHeader(),
      body: {
        social,
      },
    });
  }

  return {
    userData,
    socialIds,
    authPopupIsRequired,
    userIsAuthorized,
    userOrders,
    showUserDeletePopup,
    showAuthPopup,
    hideAuthPopup,
    updateUserData,
    setUserIdTalkMe,
    loginUsingCredentials,
    logout,
    getUser,
    getSocialRedirectUri,
    getSocialAuthUrl,
    refreshTokens,
    sendSocialCode,
    bindSocial,
    unbindSocial,
    getAuthorizationHeader,
    getUserOrders,
    processErrorLoading,
    orderPerPage,
  };
});
