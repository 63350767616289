import type { Game } from "~/types/games";
import type { ServersList } from "~/types/servers";
import type { ServerItem } from "~/types/servers";
import type { Product, StandoffOrderSettings, Topup } from "~/types/products";
import { getRefresh } from "~/utils/tokens/getRefresh";
import * as Sentry from "@sentry/vue";

type RawGameData = Game & { products: Product[] };
export type GameData = Game & { products: { amount: number; data: Product }[] };
type MoogoldOrderResponse = {
  url: string;
};

type BulldropOrderResponse = {
  url: string;
};

type StandoffOrderResponse = {
  id: string;
};

type LastTopupResponse = {
  results: Topup[];
};

export const useProductsStore = defineStore("productsStore", () => {
  /**
   * Данные игры отображаемой на странице.
   */
  const gameData = ref<GameData | null>(null);
  const { t } = useI18n();
  const noProductsError = t("topup.no_products");
  /**
   * Общее кол-во заказанных товаров.
   */
  const totalAmount = computed(
    () => gameData.value?.products.reduce((a, p) => a + p.amount, 0) || 0,
  );
  /**
   * Общая стоимость заказанных товаров.
   */
  const totalCost = computed(
    () =>
      gameData.value?.products.reduce(
        (a, p) => a + p.amount * parseFloat(p.data.price),
        0,
      ) || 0,
  );
  /**
   * Индикатор необходимости отобразить форму подтверждения заказа.
   */
  const showOrderConfirmForm = ref(false);
  /**
   * Индикатор необходимости отобразить форму ввода данных заказчика.
   */
  const showUserDataForm = ref(false);

  const baseURL = isClient()
    ? `${useRuntimeConfig().public.clientApiBase}api/`
    : `${useRuntimeConfig().public.serverApiBase}api/`;
  const userStore = useUserStore();
  const lastOrders = ref<Topup[]>();
  const moogoldGameServers = ref();
  const gameServerId = ref();

  /**
   * Получает данные игры отображаемой на странице.
   *
   * @param slug - идентификатор игры.
   * @param isShowError
   */
  async function init(slug: string, isShowError = true) {
    try {
      const { data, error } = await useFetch<RawGameData>(`/games/${slug}/`, {
        baseURL,
        credentials: "include",
      });
      if (data.value) {
        const products = data.value.products.map((data) => ({
          amount: 0,
          data,
        }));

        gameData.value = Object.assign({}, data.value, { products });
      }
      if (error.value) {
        if (!isShowError) {
          gameData.value = null;
        } else {
          showError(error.value);
        }
      }
    } catch (error: any) {
      Sentry.captureException(error);
    }
  }

  function restoreOrderIfExists() {
    const gamesOrderValue = localStorage.getItem("games");
    if (!gamesOrderValue || !gameData.value) {
      return;
    }

    const gamesOrder = JSON.parse(gamesOrderValue);

    if (
      gamesOrder &&
      typeof gamesOrder === "object" &&
      gameData.value?.id in gamesOrder
    ) {
      const gameOrder = gamesOrder[gameData.value.id];
      if (gameOrder.products && Array.isArray(gameOrder.products)) {
        const updatedGameOrder = { ...gameOrder, products: [] };
        gameOrder.products.forEach((p: { amount: number; data: Product }) => {
          const productToUpdate = gameData.value?.products.find(
            (prod) => prod.data.id === p.data.id,
          );
          if (productToUpdate) {
            updatedGameOrder.products.push({
              ...productToUpdate,
              amount: p.amount,
            });
          }
        });

        gameData.value = updatedGameOrder;

        saveOrderToStorage();
      }
    }
  }

  function saveOrderToStorage() {
    if (gameData.value?.id) {
      const gamesOrderValue = localStorage.getItem("games");
      let gamesOrder = {};
      if (!gamesOrderValue) {
        localStorage.setItem(`games`, JSON.stringify({}));
      } else {
        gamesOrder = JSON.parse(gamesOrderValue);
      }

      localStorage.setItem(
        `games`,
        JSON.stringify({ ...gamesOrder, [gameData.value.id]: gameData.value }),
      );
    }
  }

  function deleteOrderFromStorage() {
    try {
      localStorage.removeItem("games");
    } catch (error: any) {
      Sentry.captureException(error);
    }
  }

  async function getLastOrders() {
    try {
      const res = await $fetch<LastTopupResponse>(
        "/orders/?page_size=4&status=done",
        {
          headers: useUserStore().getAuthorizationHeader(),
          baseURL,
          credentials: "include",
        },
      );
      lastOrders.value = res.results;
    } catch (error: any) {
      if (error.statusCode === 401) {
        const refresh = getRefresh() || "";
        await userStore.refreshTokens(refresh);
        await getLastOrders();
      } else {
        throw error;
      }
    }
  }
  /**
   * Получает данные игры отображаемой на странице.
   *
   * @param slug - идентификатор игры.
   */
  async function setRepeatedOrderData(topup: Topup) {
    await init(topup.game.id.toString());
    if (!gameData.value) return;
    gameData.value.products = gameData.value.products.map((product) => {
      const res = topup.order_products.find(
        (orderProduct) => orderProduct.id === product.data.id,
      );
      if (res) return { data: product.data, amount: res.quantity };
      return product;
    });
  }

  /**
   * Получает список доступных серверов для игры с использование Moogold.
   *
   * @param slug - идентификатор игры.
   */
  async function getMoogoldServersList(slug: string) {
    try {
      const data = await $fetch<ServersList>(
        `/orders/moogold/servers/${slug}/`,
        {
          baseURL,
          credentials: "include",
        },
      );

      if (data.results) {
        moogoldGameServers.value = setServersObjectForSelect(data);
        gameServerId.value = data.results[0].id;
      }
    } catch (error: any) {
      Sentry.captureException(error);
    }
  }

  /**
   * Чтобы переиспользовать компонент Select нужно привести данные к одному типу.
   * Ключи приходящие с бэка не позволяют этого, поэтому формируем объект вручную.
   */
  function setServersObjectForSelect(data: ServersList) {
    const updatedData = data.results.map((server: ServerItem) => {
      const { id, ...rest } = server;
      return { value: id, ...rest };
    });
    return updatedData;
  }

  /**
   * Обновляет количество заказываемого товара используя его id.
   *
   * @param productId - id товара.
   * @param newAmount - новое количество.
   */
  function updateAmountUsingId(payload: {
    productId: number;
    newAmount: number;
  }) {
    const product = gameData.value?.products.find(
      (product) => product.data.id === payload.productId,
    );
    if (product) {
      product.amount = payload.newAmount;
      saveOrderToStorage();
    }
  }

  /**
   * Формирует корзину заказов к нужному для бэка виду и передаёт на сервер.
   */
  async function orderMoogold({
    moogoldServer,
    moogoldUserId,
    email,
  }: {
    moogoldServer: number;
    moogoldUserId: string;
    email: string;
  }) {
    const moogold = gameData.value?.products.map((product) => ({
      product_id: product.data.id,
      quantity: product.amount,
    }));
    const finalMoogold = moogold?.filter((product) => product.quantity !== 0);

    try {
      const data = await $fetch<MoogoldOrderResponse>(`/orders/moogold/`, {
        method: "POST",
        baseURL,
        credentials: "include",
        body: {
          products: finalMoogold,
          moogold_server: moogoldServer,
          moogold_user_id: moogoldUserId,
          email: email,
        },
        headers: userStore.getAuthorizationHeader(),
      });
      handlePaymentSuccess(data);
    } catch (error: any) {
      if (error.statusCode === 401) {
        const refresh = getRefresh() || "";
        await userStore.refreshTokens(refresh);
        await orderMoogold({
          moogoldServer,
          moogoldUserId,
          email,
        });
      } else {
        throw error;
      }
    }
  }

  /**
   * Формирует корзину заказов к нужному для бэка виду и передаёт на сервер.
   */
  async function orderPubg({ email }: { email: string }) {
    const moogold = gameData.value?.products.map((product) => ({
      product_id: product.data.id,
      quantity: product.amount,
    }));
    const finalMoogold = moogold?.filter((product) => product.quantity !== 0);

    try {
      const data = await $fetch<MoogoldOrderResponse>(`/orders/pubg/`, {
        method: "POST",
        baseURL,
        credentials: "include",
        body: {
          products: finalMoogold,
          email: email,
        },
        headers: userStore.getAuthorizationHeader(),
      });
      handlePaymentSuccess(data);
    } catch (error: any) {
      if (error.statusCode === 401) {
        const refresh = getRefresh() || "";
        await userStore.refreshTokens(refresh);
        await orderPubg({
          email,
        });
      } else {
        throw error;
      }
    }
  }

  /**
   * Формирует корзину заказов к нужному для бэка виду и передаёт на сервер.
   */
  async function orderBulldrop({
    email,
    bulldropUserId,
  }: {
    email: string;
    bulldropUserId: string;
  }) {
    const bulldrop = gameData.value?.products.map((product) => ({
      product_id: product.data.id,
      quantity: product.amount,
    }));
    const finalBulldrop = bulldrop?.filter((product) => product.quantity !== 0);

    try {
      const data = await $fetch<BulldropOrderResponse>(`/orders/bulldrop/`, {
        method: "POST",
        baseURL,
        credentials: "include",
        body: {
          products: finalBulldrop,
          email: email,
          bulldrop_user_id: bulldropUserId,
        },
        headers: userStore.getAuthorizationHeader(),
      });
      handlePaymentSuccess(data);
    } catch (error: any) {
      if (error.statusCode === 401) {
        const refresh = getRefresh() || "";
        await userStore.refreshTokens(refresh);
        await orderBulldrop({ email, bulldropUserId });
      } else {
        throw error;
      }
    }
  }

  /**
   * Формирует корзину заказов к нужному для бэка виду и передаёт на сервер.
   */
  async function orderStandoffAvatar({
    orderId,
    email,
    gameAvatar,
  }: {
    gameAvatar: File;
    orderId: string;
    email: string;
  }) {
    const formData = new FormData();
    formData.append("game_avatar", gameAvatar);
    formData.append("email", email);

    try {
      const data = await $fetch<MoogoldOrderResponse>(
        `/orders/standoff/avatar/${orderId}/`,
        {
          method: "POST",
          baseURL,
          credentials: "include",
          body: formData,
          headers: userStore.getAuthorizationHeader(),
        },
      );
      return data;
    } catch (error: any) {
      if (error.statusCode === 401) {
        const refresh = getRefresh() || "";
        await userStore.refreshTokens(refresh);
        await orderStandoffAvatar({
          orderId,
          email,
          gameAvatar,
        });
      } else {
        throw error;
      }
    }
  }

  /**
   * Формирует корзину заказов к нужному для бэка виду и передаёт на сервер.
   */
  async function orderStandoffRaw({
    gameAccountId,
    surplus,
    email,
  }: {
    gameAccountId: string;
    surplus: number;
    email: string;
  }) {
    const products = gameData.value?.products.map((product) => ({
      product_id: product.data.id,
      quantity: product.amount,
    }));
    const finalProducts = products?.filter((product) => product.quantity !== 0);

    try {
      const data = await $fetch<StandoffOrderResponse>(`/orders/standoff/`, {
        method: "POST",
        baseURL,
        credentials: "include",
        body: {
          game_account_id: gameAccountId,
          surplus: surplus.toString(),
          email: email,
          products: finalProducts,
        },
        headers: userStore.getAuthorizationHeader(),
      });
      return data;
    } catch (error: any) {
      if (error.statusCode === 401) {
        const refresh = getRefresh() || "";
        await userStore.refreshTokens(refresh);
        await orderStandoffRaw({
          gameAccountId,
          surplus,
          email,
        });
      } else {
        throw error;
      }
    }
  }

  /**
   * Формирует корзину заказов к нужному для бэка виду и передаёт на сервер.
   */
  async function orderStandoff({
    gameAccountId,
    gameAvatar,
    surplus,
    email,
  }: {
    gameAccountId: string;
    gameAvatar: File;
    surplus: number;
    email: string;
  }) {
    const data = await orderStandoffRaw({ gameAccountId, surplus, email });
    if (!data?.id) return;
    const avatarData = await orderStandoffAvatar({
      orderId: data?.id,
      gameAvatar,
      email,
    });
    handlePaymentSuccess(avatarData);
  }

  /**
   * Формирует корзину заказов к нужному для бэка виду и передаёт на сервер.
   */
  async function orderBulldropVoucher({ email }: { email: string }) {
    const bulldropVoucher = gameData.value?.products.map((product) => ({
      product_id: product.data.id,
      quantity: product.amount,
    }));
    const finalBulldropVoucher = bulldropVoucher?.filter(
      (product) => product.quantity !== 0,
    );

    try {
      const data = await $fetch<BulldropOrderResponse>(`/orders/voucher/`, {
        method: "POST",
        baseURL,
        credentials: "include",
        body: {
          products: finalBulldropVoucher,
          email: email,
        },
        headers: userStore.getAuthorizationHeader(),
      });
      handlePaymentSuccess(data);
    } catch (error: any) {
      if (error.statusCode === 401) {
        const refresh = getRefresh() || "";
        await userStore.refreshTokens(refresh);
        await orderBulldropVoucher({ email });
      } else {
        throw error;
      }
    }
  }

  const orderStandoffSettings = async () => {
    try {
      const data = await $fetch<StandoffOrderSettings>(
        `/orders/standoff/settings/`,
        {
          method: "GET",
          baseURL,
          credentials: "include",
          headers: userStore.getAuthorizationHeader(),
        },
      );
      return data;
    } catch (error: any) {
      if (error.statusCode === 401) {
        const refresh = getRefresh() || "";
        await userStore.refreshTokens(refresh);
        await orderStandoffSettings();
      }
    }
  };

  const notify = useNotify();

  function handlePaymentSuccess(data?: { url?: string }) {
    deleteOrderFromStorage();
    if (data && data.url) {
      reloadNuxtApp({
        path: data.url,
      });
    }
    notify({ text: t("forms.order_user_data.payment_done") });
    userStore.getUser();
    showOrderConfirmForm.value = false;
    showUserDataForm.value = false;
    if (!gameData.value) return;
    gameData.value?.products.forEach((data) => (data.amount = 0));
  }

  return {
    gameData,
    totalCost,
    totalAmount,
    showOrderConfirmForm,
    moogoldGameServers,
    showUserDataForm,
    lastOrders,
    noProductsError,
    init,
    updateAmountUsingId,
    getMoogoldServersList,
    orderMoogold,
    getLastOrders,
    setRepeatedOrderData,
    restoreOrderIfExists,
    saveOrderToStorage,
    deleteOrderFromStorage,
    orderBulldrop,
    orderStandoff,
    orderPubg,
    orderStandoffSettings,
    orderBulldropVoucher,
  };
});
