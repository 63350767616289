import { default as _91_91page_93_930c5jWXLE6DMeta } from "/app/pages/[[page]].vue?macro=true";
import { default as redirectYgIzTV8UF1Meta } from "/app/pages/auth/[social]/redirect.vue?macro=true";
import { default as contactsyTtBxebE6nMeta } from "/app/pages/info/contacts.vue?macro=true";
import { default as indexXUGwwgbzIMMeta } from "/app/pages/info/index.vue?macro=true";
import { default as privacy_45policyMHgJbA7rv3Meta } from "/app/pages/info/privacy-policy.vue?macro=true";
import { default as user_45agreementtuEiuztClhMeta } from "/app/pages/info/user-agreement.vue?macro=true";
import { default as _91slug_93LZAIemOrgDMeta } from "/app/pages/products/[slug].vue?macro=true";
import { default as _91_91page_93_93JksjpKY5yFMeta } from "/app/pages/profile/[[page]].vue?macro=true";
import { default as settingssfrdss5mooMeta } from "/app/pages/profile/settings.vue?macro=true";
export default [
  {
    name: _91_91page_93_930c5jWXLE6DMeta?.name ?? "page___ru",
    path: _91_91page_93_930c5jWXLE6DMeta?.path ?? "/:page?",
    meta: _91_91page_93_930c5jWXLE6DMeta || {},
    alias: _91_91page_93_930c5jWXLE6DMeta?.alias || [],
    redirect: _91_91page_93_930c5jWXLE6DMeta?.redirect,
    component: () => import("/app/pages/[[page]].vue").then(m => m.default || m)
  },
  {
    name: _91_91page_93_930c5jWXLE6DMeta?.name ?? "page___en",
    path: _91_91page_93_930c5jWXLE6DMeta?.path ?? "/en/:page?",
    meta: _91_91page_93_930c5jWXLE6DMeta || {},
    alias: _91_91page_93_930c5jWXLE6DMeta?.alias || [],
    redirect: _91_91page_93_930c5jWXLE6DMeta?.redirect,
    component: () => import("/app/pages/[[page]].vue").then(m => m.default || m)
  },
  {
    name: redirectYgIzTV8UF1Meta?.name ?? "auth-social-redirect___ru",
    path: redirectYgIzTV8UF1Meta?.path ?? "/auth/:social()/redirect",
    meta: redirectYgIzTV8UF1Meta || {},
    alias: redirectYgIzTV8UF1Meta?.alias || [],
    redirect: redirectYgIzTV8UF1Meta?.redirect,
    component: () => import("/app/pages/auth/[social]/redirect.vue").then(m => m.default || m)
  },
  {
    name: redirectYgIzTV8UF1Meta?.name ?? "auth-social-redirect___en",
    path: redirectYgIzTV8UF1Meta?.path ?? "/en/auth/:social()/redirect",
    meta: redirectYgIzTV8UF1Meta || {},
    alias: redirectYgIzTV8UF1Meta?.alias || [],
    redirect: redirectYgIzTV8UF1Meta?.redirect,
    component: () => import("/app/pages/auth/[social]/redirect.vue").then(m => m.default || m)
  },
  {
    name: contactsyTtBxebE6nMeta?.name ?? "info-contacts___ru",
    path: contactsyTtBxebE6nMeta?.path ?? "/info/contacts",
    meta: contactsyTtBxebE6nMeta || {},
    alias: contactsyTtBxebE6nMeta?.alias || [],
    redirect: contactsyTtBxebE6nMeta?.redirect,
    component: () => import("/app/pages/info/contacts.vue").then(m => m.default || m)
  },
  {
    name: contactsyTtBxebE6nMeta?.name ?? "info-contacts___en",
    path: contactsyTtBxebE6nMeta?.path ?? "/en/info/contacts",
    meta: contactsyTtBxebE6nMeta || {},
    alias: contactsyTtBxebE6nMeta?.alias || [],
    redirect: contactsyTtBxebE6nMeta?.redirect,
    component: () => import("/app/pages/info/contacts.vue").then(m => m.default || m)
  },
  {
    name: indexXUGwwgbzIMMeta?.name ?? "info___ru",
    path: indexXUGwwgbzIMMeta?.path ?? "/info",
    meta: indexXUGwwgbzIMMeta || {},
    alias: indexXUGwwgbzIMMeta?.alias || [],
    redirect: indexXUGwwgbzIMMeta?.redirect,
    component: () => import("/app/pages/info/index.vue").then(m => m.default || m)
  },
  {
    name: indexXUGwwgbzIMMeta?.name ?? "info___en",
    path: indexXUGwwgbzIMMeta?.path ?? "/en/info",
    meta: indexXUGwwgbzIMMeta || {},
    alias: indexXUGwwgbzIMMeta?.alias || [],
    redirect: indexXUGwwgbzIMMeta?.redirect,
    component: () => import("/app/pages/info/index.vue").then(m => m.default || m)
  },
  {
    name: privacy_45policyMHgJbA7rv3Meta?.name ?? "info-privacy-policy___ru",
    path: privacy_45policyMHgJbA7rv3Meta?.path ?? "/info/privacy-policy",
    meta: privacy_45policyMHgJbA7rv3Meta || {},
    alias: privacy_45policyMHgJbA7rv3Meta?.alias || [],
    redirect: privacy_45policyMHgJbA7rv3Meta?.redirect,
    component: () => import("/app/pages/info/privacy-policy.vue").then(m => m.default || m)
  },
  {
    name: privacy_45policyMHgJbA7rv3Meta?.name ?? "info-privacy-policy___en",
    path: privacy_45policyMHgJbA7rv3Meta?.path ?? "/en/info/privacy-policy",
    meta: privacy_45policyMHgJbA7rv3Meta || {},
    alias: privacy_45policyMHgJbA7rv3Meta?.alias || [],
    redirect: privacy_45policyMHgJbA7rv3Meta?.redirect,
    component: () => import("/app/pages/info/privacy-policy.vue").then(m => m.default || m)
  },
  {
    name: user_45agreementtuEiuztClhMeta?.name ?? "info-user-agreement___ru",
    path: user_45agreementtuEiuztClhMeta?.path ?? "/info/user-agreement",
    meta: user_45agreementtuEiuztClhMeta || {},
    alias: user_45agreementtuEiuztClhMeta?.alias || [],
    redirect: user_45agreementtuEiuztClhMeta?.redirect,
    component: () => import("/app/pages/info/user-agreement.vue").then(m => m.default || m)
  },
  {
    name: user_45agreementtuEiuztClhMeta?.name ?? "info-user-agreement___en",
    path: user_45agreementtuEiuztClhMeta?.path ?? "/en/info/user-agreement",
    meta: user_45agreementtuEiuztClhMeta || {},
    alias: user_45agreementtuEiuztClhMeta?.alias || [],
    redirect: user_45agreementtuEiuztClhMeta?.redirect,
    component: () => import("/app/pages/info/user-agreement.vue").then(m => m.default || m)
  },
  {
    name: _91slug_93LZAIemOrgDMeta?.name ?? "products-slug___ru",
    path: _91slug_93LZAIemOrgDMeta?.path ?? "/products/:slug()",
    meta: _91slug_93LZAIemOrgDMeta || {},
    alias: _91slug_93LZAIemOrgDMeta?.alias || [],
    redirect: _91slug_93LZAIemOrgDMeta?.redirect,
    component: () => import("/app/pages/products/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93LZAIemOrgDMeta?.name ?? "products-slug___en",
    path: _91slug_93LZAIemOrgDMeta?.path ?? "/en/products/:slug()",
    meta: _91slug_93LZAIemOrgDMeta || {},
    alias: _91slug_93LZAIemOrgDMeta?.alias || [],
    redirect: _91slug_93LZAIemOrgDMeta?.redirect,
    component: () => import("/app/pages/products/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91_91page_93_93JksjpKY5yFMeta?.name ?? "profile-page___ru",
    path: _91_91page_93_93JksjpKY5yFMeta?.path ?? "/profile/:page?",
    meta: _91_91page_93_93JksjpKY5yFMeta || {},
    alias: _91_91page_93_93JksjpKY5yFMeta?.alias || [],
    redirect: _91_91page_93_93JksjpKY5yFMeta?.redirect,
    component: () => import("/app/pages/profile/[[page]].vue").then(m => m.default || m)
  },
  {
    name: _91_91page_93_93JksjpKY5yFMeta?.name ?? "profile-page___en",
    path: _91_91page_93_93JksjpKY5yFMeta?.path ?? "/en/profile/:page?",
    meta: _91_91page_93_93JksjpKY5yFMeta || {},
    alias: _91_91page_93_93JksjpKY5yFMeta?.alias || [],
    redirect: _91_91page_93_93JksjpKY5yFMeta?.redirect,
    component: () => import("/app/pages/profile/[[page]].vue").then(m => m.default || m)
  },
  {
    name: settingssfrdss5mooMeta?.name ?? "profile-settings___ru",
    path: settingssfrdss5mooMeta?.path ?? "/profile/settings",
    meta: settingssfrdss5mooMeta || {},
    alias: settingssfrdss5mooMeta?.alias || [],
    redirect: settingssfrdss5mooMeta?.redirect,
    component: () => import("/app/pages/profile/settings.vue").then(m => m.default || m)
  },
  {
    name: settingssfrdss5mooMeta?.name ?? "profile-settings___en",
    path: settingssfrdss5mooMeta?.path ?? "/en/profile/settings",
    meta: settingssfrdss5mooMeta || {},
    alias: settingssfrdss5mooMeta?.alias || [],
    redirect: settingssfrdss5mooMeta?.redirect,
    component: () => import("/app/pages/profile/settings.vue").then(m => m.default || m)
  }
]